import * as i0 from '@angular/core';
import { Pipe } from '@angular/core';
class TuiFilterPipe {
  /**
   * Filters an array through a matcher function using additional arguments
   *
   * @param items array
   * @param matcher method for filtering
   * @param args arbitrary number of additional arguments
   */
  transform(items, matcher, ...args) {
    return items.filter(item => matcher(item, ...args));
  }
  static {
    this.ɵfac = function TuiFilterPipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiFilterPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "tuiFilter",
      type: TuiFilterPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiFilterPipe, [{
    type: Pipe,
    args: [{
      standalone: true,
      name: 'tuiFilter'
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiFilterPipe };
