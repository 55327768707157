<div class="selected-columns">
  @for (fieldGroup of form.controls.fields.controls; track $index) {
    @if (!isColumnIndexVisible()) {
      <div class="column no-index">
        @for (part of fieldGroup.controls.field.value | split: '.'; track $index) {
          @if ($last && !$first) {
            <tui-icon icon="@tui.chevron-right"></tui-icon>
          }
          {{ part | translate }}
        }
        @if (!fieldGroup.value.disabled && !disabled) {
          <button
            appearance="icon"
            iconStart="@tui.x"
            tuiIconButton
            type="button"
            size="s"
            (click)="removeColumn($index)"
          ></button>
        }
      </div>
    } @else {
      <label tuiBlock>
        <input
          tuiCheckbox
          type="checkbox"
          [disabled]="disabled"
          [ngModel]="fieldGroup.controls.indexed.value"
          (ngModelChange)="setColumnIndexed($event, $index)"
        />
        <div class="column">
          @for (part of fieldGroup.controls.field.value | split: '.'; track $index) {
            @if ($last && !$first) {
              <tui-icon icon="@tui.chevron-right"></tui-icon>
            }
            {{ part | translate }}
          }

          @if (!disabled) {
            <button
              appearance="icon"
              iconStart="@tui.x"
              tuiIconButton
              type="button"
              size="s"
              (click)="removeColumn($index)"
            ></button>
          }
        </div>
      </label>
    }
  }
</div>

<div class="controls tui-space_top-2">
  @if (!disabled) {
    <div
      tuiDropdownAlign="right"
      [tuiDropdown]="baseFieldList"
      [tuiDropdownSided]="true"
      [(tuiDropdownOpen)]="isDropdownOpen"
    >
      <button appearance="flat" iconStart="@tui.plus" size="m" tuiButton type="button">
        {{ 'segmentEditor.fieldSelector.addColumnButtonLabel' | translate }}
      </button>
    </div>
  }

  @if (isIndexUniqueVisible() && isAtLeastOneFieldSelected()) {
    <label tuiLabel>
      <input
        tuiCheckbox
        type="checkbox"
        [ngModel]="form.controls.isIndexUnique.value"
        (ngModelChange)="updateIsIndexUnique($event)"
        [disabled]="disabled"
        size="m"
      />
      {{ 'segmentEditor.fieldSelector.useUnqiueIndexLabel' | translate }}
    </label>
  }
</div>

<ng-template #fieldList let-meta="meta" let-rootPath="rootPath">
  <tui-data-list tuiDataListDropdownManager>
    <tui-opt-group label="Columns">
      <button
        *ngFor="let item of meta.columns"
        (click)="selectColumn(item.name, rootPath)"
        tuiOption
        [disabled]="isColumnSelected(item.Name, rootPath)"
      >
        {{ item.name | translate }}
      </button>
    </tui-opt-group>
    <tui-opt-group label="Properties">
      <button
        *ngFor="let prop of meta.properties"
        tuiDropdownAlign="right"
        tuiDropdownSided
        tuiOption
        [tuiDropdown]="nextDropdown"
        [tuiDropdownManual]="false"
      >
        {{ prop.name | translate }}
        <ng-template #nextDropdown>
          <ng-container
            *polymorpheusOutlet="
              fieldList;
              context: {
                meta: getTarget(prop),
                rootPath: (rootPath ? rootPath + '.' : '') + prop.name
              }
            "
          >
          </ng-container>
        </ng-template>
      </button>
    </tui-opt-group>
  </tui-data-list>
</ng-template>

<ng-template #baseFieldList>
  <ng-container *polymorpheusOutlet="fieldList; context: { meta: tableMetadata }">
  </ng-container>
</ng-template>
