<tui-primitive-textfield
  [value]="state ? (stateDescription | translate: state) : ''"
  (valueChange)="changeValue($event)"
  [editable]="false"
  [tuiTextfieldIcon]="arrow"
  [tuiTextfieldCleaner]="true"
  [tuiDropdown]="content"
  [tuiDropdownOpen]="!disabled && open"
  (tuiDropdownOpenChange)="open = $event"
>
  {{ definition.label | translate }}
  <input tuiTextfield style="cursor: pointer" />
</tui-primitive-textfield>

<ng-template #content>
  <div style="padding: var(--tui-padding-m); display: flex; flex-direction: column; gap: 0.5rem">
    <tui-input-range
      style="width: 200px"
      [ngModel]="[state?.from || 0, state?.to || 100]"
      (ngModelChange)="updateRange($event)"
      [min]="definition.min || 0"
      [max]="definition.max || 100"
      [tuiTextfieldLabelOutside]="true"
    >
    </tui-input-range>
  </div>
</ng-template>
