import { Type } from '@angular/core';
import {
  CommunicationActivityTypeIds,
  SegmentTypeIds
} from '@konnektu/domain-models';
import { MetastoreOperator } from '@konnektu/metastore';
import { TuiDay, TuiDayRange } from '@taiga-ui/cdk';
import { RelativeDate } from './controls/birth-date/birth-date-control.component';
import { DynamicDate } from './controls/date/date-control.component';
import { SimpleFilterControl } from './controls/simple-filter-control';

export const throwColumnOrExpressionNotSpecified = () => {
  throw new Error(
    'Definition does not specify column of expression build function'
  );
};

export type ConditionType =
  | 'checkbox'
  | 'string'
  | 'date'
  | 'birth-date'
  | 'lookup'
  | 'lookup-remote'
  | 'nullable-boolean'
  | 'number-range'
  | 'number'
  | 'communication-response'
  | 'khl-date'
  | 'khl-birth-date';

export interface BaseConditionDef<
  TState = object,
  TImpl extends SimpleFilterControl<any> = any
> {
  id: string;
  label: string;
  state?: TState;
  type: ConditionType | Type<TImpl>;
  createExpression?: (
    state: TState,
    defaultCreateExpression: (column: string, state: TState) => object
  ) => object;
  column?: string;
}

export interface CustomConditionDef<TState = object>
  extends BaseConditionDef<TState> {
  type: Type<any>;
  [key: string]: any;
}

export interface LookupConditionDef extends BaseConditionDef<number[] | null> {
  type: 'lookup';
  entityName: string;
  additionalFilter?: object;
}

export interface CommunicationResponseConditionDef
  extends BaseConditionDef<{
    hasActivity: boolean;
    responseTypeId: CommunicationActivityTypeIds;
    communications?: number[];
    period?: TuiDay | TuiDayRange | DynamicDate;
    links?: string[];
  } | null> {
  type: 'communication-response';
}

export interface LookupRemoteConditionDef
  extends BaseConditionDef<number[] | null> {
  type: 'lookup-remote';
  entityName: string;
  additionalFilter?: object;
}

export interface NullableBooleanConditionDef
  extends BaseConditionDef<boolean | null> {
  type: 'nullable-boolean';
}

export interface CheckboxConditionDef extends BaseConditionDef<boolean> {
  type: 'checkbox';
}

export interface StringConditionDef
  extends BaseConditionDef<{
    value: string;
    operator: MetastoreOperator;
  } | null> {
  type: 'string';
}

export interface DateConditionDef
  extends BaseConditionDef<
    TuiDay | TuiDayRange | DynamicDate | NotFilledInValue | null
  > {
  type: 'date';
}

export interface BirthDateConditionDef
  extends BaseConditionDef<RelativeDate | NotFilledInValue | null> {
  type: 'birth-date';
}

export interface NumberRangeConditionDef
  extends BaseConditionDef<{
    from: number;
    to: number;
  } | null> {
  type: 'number-range';
  min?: number;
  max?: number;
}

export interface NumberConditionDef
  extends BaseConditionDef<{
    operator: MetastoreOperator;
    value: number | null;
  } | null> {
  type: 'number';
  min?: number;
  max?: number;
  allowDecimal?: boolean;
}

export type ConditionDef =
  | LookupConditionDef
  | LookupRemoteConditionDef
  | NullableBooleanConditionDef
  | CheckboxConditionDef
  | StringConditionDef
  | DateConditionDef
  | BirthDateConditionDef
  | NumberRangeConditionDef
  | NumberConditionDef
  | CommunicationResponseConditionDef
  | CustomConditionDef;

export interface ConditionBlockDef {
  id: string;
  label: string;
  modifyExpressions?: (expressions: object[]) => object[];
  conditions: ConditionDef[];
}

export interface SimpleFilterTemplate {
  id: string;
  label: string;
  entityName: string;
  segmentTypeId?: SegmentTypeIds;
  columns: { column: string; index: boolean }[];
  isIndexUnique: boolean;
  additionalFilter?: object;
  conditionBlocks: ConditionBlockDef[];
}

export class NotFilledInValue {}
