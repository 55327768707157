import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  computed
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MetastoreOperator } from '@konnektu/metastore';
import { TranslateModule } from '@ngx-translate/core';
import { TuiDataList } from '@taiga-ui/core';
import {
  TuiSelectModule,
  TuiTextfieldControllerModule
} from '@taiga-ui/legacy';
import { NullableBooleanConditionDef } from '../models';
import { SimpleFilterControl } from './simple-filter-control';

@Component({
  selector: 'knk-simple-filter-nullable-boolean-control',
  template: `
    <tui-select
      [valueContent]="currentValueContent || '' | translate"
      [ngModel]="currentSelectValue()"
      [disabled]="disabled"
      (ngModelChange)="setValueFromSelect($event)"
    >
      {{ definition.label | translate }}
      <tui-data-list *tuiDataList>
        <button tuiOption [value]="'null'">
          {{
            'segmentEditor.simpleFilter.controls.booleanLookupNotFilledIn'
              | translate
          }}
        </button>
        <button tuiOption [value]="'true'">
          {{
            'segmentEditor.simpleFilter.controls.booleanLookupTrueLabel'
              | translate
          }}
        </button>
        <button tuiOption [value]="'false'">
          {{
            'segmentEditor.simpleFilter.controls.booleanLookupFalseLabel'
              | translate
          }}
        </button>
      </tui-data-list>
    </tui-select>
  `,
  styles: [
    `
      tui-select {
        width: 300px;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    TranslateModule,
    TuiSelectModule,
    TuiDataList,
    TuiTextfieldControllerModule
  ]
})
export class NullableBooleanControlComponent
  extends SimpleFilterControl<NullableBooleanConditionDef>
  implements OnInit
{
  currentSelectValue = computed(() =>
    this.state === null ? 'null' : this.state === false ? 'false' : 'true'
  );

  get currentValueContent() {
    return this.state === null
      ? 'segmentEditor.simpleFilter.controls.booleanLookupNotFilledIn'
      : this.state
        ? 'segmentEditor.simpleFilter.controls.booleanLookupTrueLabel'
        : 'segmentEditor.simpleFilter.controls.booleanLookupFalseLabel';
  }

  ngOnInit() {
    this.updateStateAndExpression(this.state ?? null);
  }

  parseState(state: boolean): boolean {
    return state;
  }

  override updateStateAndExpression(state: boolean | null): void {
    super.updateStateAndExpression(state);
  }

  setValueFromSelect(selected: 'true' | 'false' | 'null') {
    this.updateStateAndExpression(
      selected === 'null' ? null : selected === 'false' ? false : true
    );
  }

  createExpression(column: string, state: boolean) {
    return {
      [column]: {
        [state === null ? MetastoreOperator.is : MetastoreOperator.eq]: state
      }
    };
  }
}
