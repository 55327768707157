<knk-common-dialog (cancelClicked)="cancel()" (doneClicked)="done()">
  <div class="knk-edit-raw-segment-dialog">
    <knk-text-input [(ngModel)]="name" [placeholder]="'Name' | translate" [label]="'Name' | translate"></knk-text-input>

    <ngx-monaco-editor
      [(ngModel)]="rawSql"
      [options]="{
        language: 'sql',
        automaticLayout: true
      }"
    ></ngx-monaco-editor>
  </div>
</knk-common-dialog>
