import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  and,
  equal,
  greaterThanOrEqual,
  isNull,
  lessThanOrEqual
} from '@konnektu/metastore';
import { TranslateModule } from '@ngx-translate/core';
import { TuiDay, TuiDayRange } from '@taiga-ui/cdk';
import { TuiDropdown } from '@taiga-ui/core';
import { TuiTabs } from '@taiga-ui/kit';
import {
  TUI_ARROW,
  TuiPrimitiveTextfieldModule,
  TuiTextfieldControllerModule
} from '@taiga-ui/legacy';
import { addDays } from 'date-fns';
import { DateConditionDef, NotFilledInValue } from '../../models';
import { DateSelectorComponent } from '../date-selector/date-selector.component';
import { SimpleFilterControl } from '../simple-filter-control';

export class DynamicDate {
  constructor(public daysAgo: number) {}
}

@Component({
  selector: 'knk-simple-filter-date-control',
  templateUrl: 'date-control.component.html',
  styleUrl: 'date-control.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    TuiDropdown,
    TuiTabs,
    TuiTextfieldControllerModule,
    TuiPrimitiveTextfieldModule,
    DateSelectorComponent
  ]
})
export class DateControlComponent
  extends SimpleFilterControl<DateConditionDef>
  implements OnInit
{
  readonly arrow = TUI_ARROW;

  open = false;

  selectedDate: TuiDay | TuiDayRange | DynamicDate | null = null;

  get stateDescription() {
    if (this.state instanceof NotFilledInValue) {
      return 'segmentEditor.simpleFilter.controls.birthDateNotFilledIn';
    }
    if (this.state instanceof TuiDay) {
      return this.state.getFormattedDay('DMY', '.');
    }
    if (this.state instanceof TuiDayRange) {
      return this.state.getFormattedDayRange('DMY', '.');
    }
    if (this.state instanceof DynamicDate) {
      return 'segmentEditor.simpleFilter.controls.dateDynamicSelected';
    }
    return '';
  }

  get stateDescriptionParams() {
    if (this.state instanceof DynamicDate) {
      return { daysAgo: this.state.daysAgo };
    }
    return {};
  }

  ngOnInit(): void {
    this.updateStateAndExpression(this.state || null);
  }

  changeValue(event: string) {
    if (event === '') {
      this.updateStateAndExpression(null);
    }
  }

  onDateUpdate(date: TuiDay | TuiDayRange | DynamicDate | NotFilledInValue) {
    this.updateStateAndExpression(date);
  }

  parseState(
    state:
      | object
      | TuiDay
      | NotFilledInValue
      | TuiDayRange
      | DynamicDate
      | null
      | undefined
  ): TuiDay | TuiDayRange | NotFilledInValue | DynamicDate | null {
    if (
      state instanceof TuiDay ||
      state instanceof TuiDayRange ||
      state instanceof DynamicDate ||
      state instanceof NotFilledInValue
    ) {
      return state;
    } else {
      if (state === null) {
        return new NotFilledInValue();
      }
      if (!state) {
        return null;
      }
      if (typeof state === 'string') {
        return TuiDay.jsonParse(state);
      }
      if ('from' in state && 'to' in state) {
        return new TuiDayRange(
          TuiDay.jsonParse(state.from as string),
          TuiDay.jsonParse(state.to as string)
        );
      }
      if ('daysAgo' in state) {
        return new DynamicDate(state.daysAgo as number);
      }
    }
    return null;
  }

  createExpression(column: string, state: typeof this._state | undefined) {
    if (!state) {
      return {};
    }
    if (state instanceof TuiDay) {
      const localDate = state.toLocalNativeDate();
      return and(
        greaterThanOrEqual(column, localDate.toISOString()),
        lessThanOrEqual(column, addDays(localDate, 1).toISOString())
      );
    }
    if (state instanceof TuiDayRange) {
      const localDateFrom = state.from.toLocalNativeDate();
      const localDateTo = state.to.toLocalNativeDate();
      return and(
        greaterThanOrEqual(column, localDateFrom.toISOString()),
        lessThanOrEqual(column, localDateTo.toISOString())
      );
    }
    if (state instanceof NotFilledInValue) {
      return isNull(column);
    } else {
      return equal(column, `$utcnow - $days:${state.daysAgo}`);
    }
  }

  override modifyState(
    state: object | TuiDay | TuiDayRange | DynamicDate | null | undefined
  ): TuiDay | TuiDayRange | DynamicDate | null {
    if (state instanceof TuiDay) {
      if (this.state === null || this.state instanceof DynamicDate) {
        return state;
      }
      if (this.state instanceof TuiDay) {
        if (this.state.dayBefore(state)) {
          return new TuiDayRange(this.state, state);
        }
        if (this.state.dayAfter(state)) {
          return new TuiDayRange(state, this.state);
        }
      }
    }
    return state as TuiDay | TuiDayRange | DynamicDate | null;
  }
}
