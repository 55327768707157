import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MetastoreOperator, equal, isNull, or } from '@konnektu/metastore';
import { TranslateModule } from '@ngx-translate/core';
import { TuiDataList, TuiDropdown, TuiIcon, TuiLink } from '@taiga-ui/core';
import {
  TuiInputNumberModule,
  TuiSelectModule,
  TuiTextfieldControllerModule
} from '@taiga-ui/legacy';
import { NumberConditionDef } from '../models';
import { SimpleFilterControl } from './simple-filter-control';

@Component({
  selector: 'knk-simple-filter-number-control',
  template: `
    <tui-select
      [valueContent]="currentOperatorLabel || '' | translate"
      [ngModel]="state?.operator || null"
      [disabled]="disabled"
      (ngModelChange)="updateOperator($event)"
      [tuiTextfieldCleaner]="true"
    >
      {{ definition.label | translate }}
      <tui-data-list *tuiDataList>
        <button *ngFor="let item of operators" tuiOption [value]="item.value">
          {{ item.label | translate }}
        </button>
      </tui-data-list>
    </tui-select>

    <tui-input-number
      [ngModel]="state?.value"
      [disabled]="disabled"
      *ngIf="showInput"
      [tuiTextfieldLabelOutside]="true"
      (ngModelChange)="updateInputValue($event)"
    >
      {{ 'segmentEditor.simpleFilter.controls.valuePlaceholder' | translate }}
    </tui-input-number>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: flex;
      }

      tui-select {
        width: 300px;
      }

      tui-input-number {
        width: 200px;
        margin-left: var(--space-m);
      }
    `
  ],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    TuiDropdown,
    TuiDataList,
    TuiIcon,
    TuiSelectModule,
    TuiLink,
    TuiInputNumberModule,
    TuiTextfieldControllerModule
  ]
})
export class NumberControlComponent
  extends SimpleFilterControl<NumberConditionDef>
  implements OnInit
{
  readonly operators = [
    {
      value: MetastoreOperator.eq,
      label: 'segmentEditor.simpleFilter.controls.equalOperator'
    },
    {
      value: MetastoreOperator.ne,
      label: 'segmentEditor.simpleFilter.controls.notEqualOperator'
    },
    {
      value: MetastoreOperator.isNot,
      label: 'segmentEditor.simpleFilter.controls.filledOperator'
    },
    {
      value: MetastoreOperator.is,
      label: 'segmentEditor.simpleFilter.controls.notFilledOperator'
    },
    {
      value: MetastoreOperator.gt,
      label: 'segmentEditor.simpleFilter.controls.greaterThanOperator'
    },
    {
      value: MetastoreOperator.gte,
      label: 'segmentEditor.simpleFilter.controls.greaterThanOrEqualOperator'
    },
    {
      value: MetastoreOperator.lt,
      label: 'segmentEditor.simpleFilter.controls.lowerThanOperator'
    },
    {
      value: MetastoreOperator.lte,
      label: 'segmentEditor.simpleFilter.controls.lowerThanOrEqualOperator'
    }
  ];

  get showInput() {
    return this.state
      ? !this.isOperatorWithoutValue(this.state.operator)
      : false;
  }

  get currentOperatorLabel() {
    if (this.state?.operator) {
      return (
        this.operators.find((o) => o.value === this.state?.operator)?.label ||
        ''
      );
    }
    return '';
  }

  ngOnInit(): void {
    this.updateStateAndExpression(this.state || null);
  }

  parseState(state: null | { operator: MetastoreOperator; value: number }) {
    return state;
  }

  isOperatorWithoutValue(operator: MetastoreOperator) {
    return (
      !operator ||
      [MetastoreOperator.is, MetastoreOperator.isNot].includes(operator)
    );
  }

  updateOperator(operator: MetastoreOperator | null) {
    if (operator === null) {
      this.updateStateAndExpression(null);
    } else {
      if (this.isOperatorWithoutValue(operator)) {
        this.updateStateAndExpression({
          value: null,
          operator
        });
      } else {
        this.updateStateAndExpression({
          value: this.state?.value || 0,
          operator
        });
      }
    }
  }

  updateInputValue(value: number) {
    this.updateStateAndExpression({
      operator: this.state?.operator || MetastoreOperator.eq,
      value
    });
  }

  createExpression(
    column: string,
    state: { value: number; operator: MetastoreOperator } | null
  ) {
    if (!state) {
      return {};
    }
    if (state && state.operator === MetastoreOperator.is && state.value === 0) {
      return or(equal(column, ''), isNull(column));
    }
    if (this.isOperatorWithoutValue(state.operator)) {
      return {
        [column]: { [state.operator]: null }
      };
    } else {
      return {
        [column]: { [state.operator]: state.value }
      };
    }
  }
}
