import { CommonModule } from '@angular/common';
import { Component, DestroyRef, OnInit, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  CommunicationActivityTypeIds,
  CommunicationChannelIds
} from '@konnektu/domain-models';
import {
  MetastoreService,
  and,
  equal,
  exist,
  from,
  greaterThanOrEqual,
  lessThanOrEqual,
  notExist,
  or
} from '@konnektu/metastore';
import {
  TenantRouterLinkDirective,
  injectTenantNavigate
} from '@konnektu/multitenant';
import { TranslateModule } from '@ngx-translate/core';
import {
  TUI_DEFAULT_MATCHER,
  TuiDay,
  TuiDayRange,
  TuiFilterPipe,
  TuiLet,
  tuiPure
} from '@taiga-ui/cdk';
import { TuiDataList, TuiDropdown, TuiIcon, TuiLink } from '@taiga-ui/core';
import { TuiFilter, TuiInputInline } from '@taiga-ui/kit';
import {
  TuiInputModule,
  TuiInputTagModule,
  TuiMultiSelectModule,
  TuiSelectModule,
  TuiTagModule,
  TuiTextfieldControllerModule
} from '@taiga-ui/legacy';
import { sortBy } from 'lodash-es';
import { Subject, catchError, first, map, of, switchMap } from 'rxjs';
import {
  CommunicationResponseConditionDef,
  NotFilledInValue
} from '../../models';
import { DateSelectorComponent } from '../date-selector/date-selector.component';
import { DynamicDate } from '../date/date-control.component';
import { SimpleFilterControl } from '../simple-filter-control';

@Component({
  selector: 'knk-simple-filter-communication-response-control',
  templateUrl: 'communication-response-control.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    TuiDataList,
    TuiLet,
    TuiInputModule,
    TuiInputTagModule,
    TuiSelectModule,
    TuiMultiSelectModule,
    TuiDropdown,
    TuiInputInline,
    TuiLink,
    TuiTagModule,
    TuiFilter,
    TuiIcon,
    TuiFilterPipe,
    TuiTextfieldControllerModule,
    DateSelectorComponent,
    TenantRouterLinkDirective
  ],
  styleUrls: ['communication-response-control.component.scss']
})
export class CommunicationResponseControlComponent
  extends SimpleFilterControl<CommunicationResponseConditionDef>
  implements OnInit
{
  private readonly metastore = inject(MetastoreService);

  private readonly destroyRef = inject(DestroyRef);

  private readonly tenantNavigate = injectTenantNavigate();

  search = '';

  CommunicationActivityTypeId = CommunicationActivityTypeIds;

  CommunicationChannel = CommunicationChannelIds;

  readonly filterItems = [
    'Email',
    'Sms',
    'Push',
    'Telegram',
    'Viber',
    'WhatsApp'
  ] as const;

  selectedChannelFilters: (typeof this.filterItems)[] = [];

  linkInput = new FormControl('');

  channelFilterUpdate = new Subject<(typeof this.filterItems)[number][]>();

  get stateDescription() {
    if (this.state?.period instanceof TuiDay) {
      return this.state.period.getFormattedDay('DMY', '.');
    }
    if (this.state?.period instanceof TuiDayRange) {
      return this.state.period.getFormattedDayRange('DMY', '.');
    }
    if (this.state?.period instanceof DynamicDate) {
      return 'segmentEditor.simpleFilter.controls.dateDynamicSelected';
    }
    return 'segmentEditor.simpleFilter.controls.commResponseAnyTime';
  }

  get stateDescriptionParams() {
    if (this.state?.period instanceof DynamicDate) {
      return { daysAgo: this.state.period.daysAgo };
    }
    return {};
  }

  get clickResponseLinkDescription() {
    if (
      this.state?.responseTypeId === CommunicationActivityTypeIds.Click &&
      this.state.links?.length
    ) {
      return 'segmentEditor.simpleFilter.controls.commResponseClickLinks';
    }
    return 'segmentEditor.simpleFilter.controls.commResponseClickAnyLink';
  }

  readonly filterMatcher = (item: { Name: string }, search: string) =>
    TUI_DEFAULT_MATCHER(item.Name, search);

  readonly selectedCommunications = signal<{ Id: number; Name: string }[]>([]);

  convertChannelNameToId(name: (typeof this.filterItems)[number]) {
    return this.CommunicationChannel[name];
  }

  @tuiPure
  getItems() {
    return this.channelFilterUpdate.pipe(
      map((channels) => {
        const channelIds = channels.map((c) => this.convertChannelNameToId(c));
        const request = from('Communication').select(['Id', 'Name']);
        if (!channelIds.length) {
          return null;
        }
        if (channelIds.length === 1) {
          return request.where(equal('Provider.ChannelId', channelIds[0]));
        } else {
          return request.where(
            or(...channelIds.map((c) => equal('Provider.ChannelId', c)))
          );
        }
      }),
      switchMap((request) =>
        request === null
          ? of([])
          : this.metastore
              .select<{ Id: number; Name: string }>(request.done())
              .pipe(
                map((values) => values.filter((v) => v.Name)),
                map((items) =>
                  sortBy(
                    items,
                    (i) => !this.state?.communications?.includes(i.Id)
                  )
                )
              )
      )
    );
  }

  ngOnInit(): void {
    if (this.definition.state && !this.state) {
      this.updateStateAndExpression(this.definition.state);
    }
    this.channelFilterUpdate.next([]);
  }

  hasChannelFilter(channel: typeof this.filterItems) {
    return this.selectedChannelFilters.includes(channel);
  }

  toggleChannelFilter(channel: typeof this.filterItems) {
    if (this.selectedChannelFilters.includes(channel)) {
      this.selectedChannelFilters = this.selectedChannelFilters.filter(
        (c) => c !== channel
      );
    } else {
      this.selectedChannelFilters.push(channel);
    }
  }

  addLink() {
    if (this.linkInput.value) {
      if (this.state?.links) {
        this.updateLinks([...this.state.links, this.linkInput.value]);
      } else {
        this.updateLinks([this.linkInput.value]);
      }
      this.linkInput.reset();
    }
  }

  handleLinkEdit(link: string, index: number) {
    if (this.state?.links?.length && !link) {
      this.state.links.splice(index, 1);
      this.updateStateAndExpression(this.state);
    }
  }

  updatePeriod(period?: TuiDay | TuiDayRange | DynamicDate | NotFilledInValue) {
    if (this.state && !(period instanceof NotFilledInValue)) {
      this.state.period = period;
      this.updateStateAndExpression(this.state);
    }
  }

  clearPeriod() {
    this.updatePeriod(undefined);
  }

  protected getSelectedCommunications(ids: number[]) {
    if (ids?.length) {
      return this.metastore.select<{
        Id: number;
        Name: string;
      }>(
        from('Communication')
          .select(['Id', 'Name'])
          .where(
            ids.length === 1
              ? equal('Id', ids[0])
              : or(...ids.map((id) => equal('Id', id)))
          )
          .done()
      );
    }
    return of([]);
  }

  private getPeriodExpression(
    state: CommunicationResponseConditionDef['state']
  ) {
    if (!state) {
      return null;
    }
    if (!state.period) {
      return null;
    }
    if (state.period instanceof TuiDay) {
      return equal('CreatedOn', state.period.toLocalNativeDate().toISOString());
    }
    if (state.period instanceof TuiDayRange) {
      const fromDate = state.period.from.toLocalNativeDate().toISOString();
      const toDate = state.period.to.toLocalNativeDate().toISOString();
      return and(
        greaterThanOrEqual('CreatedOn', fromDate),
        lessThanOrEqual('CreatedOn', toDate)
      );
    }
    if (state.period instanceof DynamicDate) {
      return equal('CreatedOn', `$utcnow - $days:${state.period.daysAgo}`);
    }
    return null;
  }

  private getLinksExpression(
    state: CommunicationResponseConditionDef['state']
  ) {
    if (!state?.links || !state.links.length) {
      return null;
    }
    if (state.links.length === 1) {
      return equal('Url', state.links[0]);
    }
    return or(...state.links.map((link) => equal('Url', link)));
  }

  private getCommunicationsExpression(
    state: CommunicationResponseConditionDef['state']
  ) {
    if (!state?.communications || !state.communications.length) {
      return null;
    }
    if (state.communications.length === 1) {
      return equal(
        'Identifier.Dispatch.Message.CommunicationId',
        state.communications[0]
      );
    }
    return or(
      ...state.communications.map((id) =>
        equal('Identifier.Dispatch.Message.CommunicationId', id)
      )
    );
  }

  createExpression(
    column: string,
    state: CommunicationResponseConditionDef['state']
  ): any {
    if (!state) {
      return null;
    }
    const innerExpressions: object[] = [];
    innerExpressions.push(equal('TypeId', state.responseTypeId));

    const communicationsExpression = this.getCommunicationsExpression(state);
    if (communicationsExpression) {
      innerExpressions.push(communicationsExpression);
    }

    const periodExpression = this.getPeriodExpression(state);
    if (periodExpression) {
      innerExpressions.push(periodExpression);
    }

    const linkExpression = this.getLinksExpression(state);
    if (linkExpression) {
      innerExpressions.push(linkExpression);
    }

    return (state.hasActivity ? exist : notExist)(
      column,
      innerExpressions.length > 1
        ? and(...innerExpressions)
        : innerExpressions[0]
    );
  }

  updateCommunications(value: number[]) {
    if (this.state) {
      this.state.communications = value;
      this.updateStateAndExpression(this.state);
    }
  }

  updateLinks(value: string[]) {
    if (this.state) {
      this.state.links = value;
      this.updateStateAndExpression(this.state);
    }
  }

  updateHasActivity(hasActivity: boolean) {
    if (this.state) {
      this.state.hasActivity = hasActivity;
      this.updateStateAndExpression(this.state);
    }
  }

  updateActivityType(typeId: CommunicationActivityTypeIds) {
    if (this.state) {
      this.state.responseTypeId = typeId;
      this.updateStateAndExpression(this.state);
    }
  }

  parseState(
    state: {
      hasActivity: boolean;
      responseTypeId: CommunicationActivityTypeIds;
      communications?: number[];
      links?: string[];
      period?: any;
    } | null
  ) {
    let parsedPeriod: TuiDay | TuiDayRange | DynamicDate | undefined;
    if (!state) {
      return null;
    }
    if (
      state.period instanceof TuiDay ||
      state.period instanceof TuiDayRange ||
      state.period instanceof DynamicDate ||
      state.period === null
    ) {
      return state;
    } else {
      if (!state.period) {
        parsedPeriod = undefined;
      } else if (typeof state.period === 'string') {
        parsedPeriod = TuiDay.jsonParse(state.period);
      } else if ('from' in state.period && 'to' in state.period) {
        parsedPeriod = new TuiDayRange(
          TuiDay.jsonParse(state.period.from as string),
          TuiDay.jsonParse(state.period.to as string)
        );
      } else if ('daysAgo' in state.period) {
        parsedPeriod = new DynamicDate(state.period.daysAgo as number);
      }
    }
    this.getSelectedCommunications(state.communications ?? [])
      .pipe(
        first(),
        takeUntilDestroyed(this.destroyRef),
        catchError(() => of([]))
      )
      .subscribe((items) => {
        this.selectedCommunications.set(items);
      });
    return { ...state, period: parsedPeriod };
  }
}
