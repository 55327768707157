<knk-default-dialog
  [header]="'segmentSection.blockingDialog.header' | translate"
  [doneLabel]="'segmentSection.blockingDialog.okButton' | translate"
  [cancelLabel]="''"
  [showCancel]="false"
  [doneEnabled]="true"
  (done)="done()"
>
  <div class="flex flex-col gap-4">
    <p class="text-gray-600">
      {{ 'segmentSection.blockingDialog.description' | translate }}
    </p>

    <div class="space-y-2 max-h-[300px] overflow-y-auto">
      <div
        *ngFor="let campaign of context.data.campaigns"
        class="group"
      >
        <a
          [routerLink]="['/', tenantCode, 'marketing-campaigns', campaign.id]"
          class="flex items-center p-2 rounded hover:bg-gray-50 transition-all"
          (click)="done()"
        >
          <tui-icon
            src="@tui.chevron-right"
            class="text-gray-400 group-hover:text-purple-600 mr-3"
          ></tui-icon>
          <span class="text-gray-700 group-hover:text-gray-900">
            {{ campaign.name }}
          </span>
        </a>
      </div>
    </div>
  </div>
</knk-default-dialog>
