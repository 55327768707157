import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  input,
  Input,
  Output
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TuiDay, TuiDayRange, TuiMonth } from '@taiga-ui/cdk';
import { TuiCalendar, TuiNumberFormat } from '@taiga-ui/core';
import { TuiBlock, TuiRadio } from '@taiga-ui/kit';
import {
  TuiInputNumberModule,
  TuiTextfieldControllerModule
} from '@taiga-ui/legacy';
import { NotFilledInValue } from '../../models';
import { DynamicDate } from '../date/date-control.component';

@Component({
  selector: 'knk-date-selector',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    TuiCalendar,
    TuiTextfieldControllerModule,
    TuiBlock,
    TuiRadio,
    TuiInputNumberModule,
    TuiNumberFormat
  ],
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateSelectorComponent {
  readonly enableNotFilledInOption = input(true);

  private _date: TuiDay | TuiDayRange | DynamicDate | NotFilledInValue | null =
    null;

  @Input() set date(
    val: TuiDay | TuiDayRange | DynamicDate | NotFilledInValue | null
  ) {
    if (val instanceof DynamicDate) {
      switch (val.daysAgo) {
        case 0:
          this.selectedDynamicDate = this.todayDynamicDate;
          break;
        case 1:
          this.selectedDynamicDate = this.yesterdayDynamicDay;
          break;
        default:
          this.dynamicDate.daysAgo = val.daysAgo;
          this.selectedDynamicDate = this.dynamicDate;
      }
    } else {
      if (val instanceof TuiDay) {
        this.firstMonth = new TuiMonth(val.year, val.month);
        this.lastMonth = this.firstMonth.append({ month: 1 });
      }
      if (val instanceof TuiDayRange) {
        this.firstMonth = new TuiMonth(val.from.year, val.from.month);
        this.lastMonth = new TuiMonth(val.to.year, val.to.month);
      }
    }
    this._date = val;
  }

  get date() {
    return this._date;
  }

  @Input() mode: 'calendar' | 'dynamic' | 'both' = 'both';

  @Output() dateChange = new EventEmitter<
    TuiDay | TuiDayRange | DynamicDate | NotFilledInValue
  >();

  hoveredItem: TuiDay | null = null;

  firstMonth = TuiMonth.currentLocal();

  lastMonth = TuiMonth.currentLocal().append({ month: 1 });

  selectedDynamicDate: DynamicDate | null = null;

  readonly todayDynamicDate = new DynamicDate(0);

  readonly yesterdayDynamicDay = new DynamicDate(1);

  readonly dynamicDate = new DynamicDate(7);

  readonly dateNotFilledIn = new NotFilledInValue();

  get stateAsTuiDates() {
    if (this.date instanceof TuiDay || this.date instanceof TuiDayRange) {
      return this.date;
    }
    return null;
  }

  onCalendarDayClick(day: TuiDay) {
    if (!this.date || this.date instanceof DynamicDate) {
      this.date = day;
      this.dateChange.emit(this.date);
    }
    if (this.date instanceof TuiDay) {
      if (this.date.dayBefore(day)) {
        this.date = new TuiDayRange(this.date, day);
        this.dateChange.emit(this.date);
      } else if (this.date.dayAfter(day)) {
        this.date = new TuiDayRange(day, this.date);
        this.dateChange.emit(this.date);
      }
    } else {
      this.date = day;
      this.dateChange.emit(this.date);
    }
    this.selectedDynamicDate = null;
  }

  onMonthChangeFirst(month: TuiMonth): void {
    this.firstMonth = month;
  }

  onMonthChangeLast(month: TuiMonth): void {
    this.lastMonth = month;
  }

  onDynamicDateSelect(date: DynamicDate) {
    this.selectedDynamicDate = date;
    this.date = this.selectedDynamicDate;
    this.dateChange.emit(this.date);
  }

  setDateNotFilledIn() {
    this.date = this.dateNotFilledIn;
    this.dateChange.emit(this.date);
  }

  updateDaysAgo(daysAgo: number) {
    this.dynamicDate.daysAgo = daysAgo;
    this.onDynamicDateSelect(this.dynamicDate);
  }
}
