<knk-section
  id="segmentVersionsSection"
  entityName="SegmentVersion"
  [enableFilter]="false"
  [showFolders]="false"
  [data]="getSegmentVersions"
  [styleOnHover]="false"
  [columns]="[
    {
      id: 'version',
      header: 'segmentVersion' | translate,
      sortable: false
    },
    {
      id: 'timestamp',
      header: 'segmentVersionCalculateTime' | translate,
      sortable: false,
      type: 'date'
    },
    {
      id: 'addedCount',
      header: 'segmentVersionAdded' | translate,
      content: addedTemplate
    },
    {
      id: 'removedCount',
      header: 'segmentVersionRemoved' | translate,
      sortable: false,
      content: removedTemplate
    }
  ]"
>
</knk-section>

<ng-template #addedTemplate let-version>
  <div class="knk-segment-version-section__added-icon">
    <knk-icon key="expand_less"></knk-icon>
    {{ version.added?.count ?? 0 }}
  </div>
</ng-template>

<ng-template #removedTemplate let-version>
  <div *ngIf="version.removed" class="knk-segment-version-section__removed-icon">
    <knk-icon key="expand_more"></knk-icon>
    {{ version.removed?.count ?? 0 }}
  </div>
</ng-template>
