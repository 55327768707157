import { inject } from '@angular/core';
import { MarketingCampaignListItemDto } from '@konnektu/marketing-campaigns/data';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@taiga-ui/polymorpheus';
import { SegmentBlockingDialogComponent } from './segment-blocking-dialog.component';

export function injectSegmentBlockingDialog() {
  const tuiDialogService = inject(TuiDialogService);
  return (campaigns: MarketingCampaignListItemDto[]) =>
    tuiDialogService.open<void>(
      new PolymorpheusComponent(SegmentBlockingDialogComponent),
      {
        data: { campaigns }
      }
    );
}
