import * as i0 from '@angular/core';
import { inject, Directive, Input } from '@angular/core';
import { tuiProvide } from '@taiga-ui/cdk/utils/miscellaneous';
import { TUI_NUMBER_FORMAT } from '@taiga-ui/core/tokens';
import { Observable, combineLatest, map, ReplaySubject } from 'rxjs';
class TuiNumberFormat extends Observable {
  constructor() {
    super(subscriber => combineLatest([this.parent, this.settings]).pipe(map(([parent, settings]) => ({
      ...parent,
      ...settings
    }))).subscribe(subscriber));
    this.settings = new ReplaySubject(1);
    this.parent = inject(TUI_NUMBER_FORMAT, {
      skipSelf: true
    });
  }
  set tuiNumberFormat(format) {
    this.settings.next(format);
  }
  static {
    this.ɵfac = function TuiNumberFormat_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiNumberFormat)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiNumberFormat,
      selectors: [["", "tuiNumberFormat", ""]],
      inputs: {
        tuiNumberFormat: "tuiNumberFormat"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([tuiProvide(TUI_NUMBER_FORMAT, TuiNumberFormat)]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiNumberFormat, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[tuiNumberFormat]',
      providers: [tuiProvide(TUI_NUMBER_FORMAT, TuiNumberFormat)]
    }]
  }], function () {
    return [];
  }, {
    tuiNumberFormat: [{
      type: Input
    }]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiNumberFormat };
