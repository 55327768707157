<knk-section
  [filters]="filtersTemplate"
  [enableFilter]="false"
  [showFolders]="false"
  [data]="data$"
  [enablePagination]="false"
  paginationMode="infinite"
  [loading]="loading()"
  [pagination]="pagination()"
  (paginationChange)="updatePagination($event)"
  [sort]="sort()"
  (sortChange)="updateSort($event)"
  (entityClicked)="redirectToSegment($event)"
  [columns]="[
    { id: 'id', header: 'Id', sortable: false },
    {
      id: 'timestamp',
      header: 'CreatedOn' | translate,
      sortable: true,
      type: 'date'
    },
    {
      id: 'name',
      header: 'Name' | translate,
      sortable: true
    },
    {
      id: 'type',
      header: 'Type' | translate,
      content: typeTemplate
    },
    {
      id: 'entityName',
      header: 'common.Entity' | translate,
      content: entityNameTemplate
    },
    {
      id: 'currentVersion',
      header: 'common.Version count' | translate,
      content: currentVersionTemplate,
      sortable: false
    },
    {
      id: 'lastRecountTime',
      header: 'segmentSection.lastRecountTime' | translate,
      content: lastRecountTimeTemplate
    },
    {
      id: 'entriesCount',
      header: 'segmentSection.entriesCount' | translate,
      content: entriesCountTemplate
    },
    {
      id: 'actionButton',
      isFunctionColumn: true,
      content: actionButton
    }
  ]"
>
</knk-section>

<ng-template #typeTemplate let-segment>
  <span [ngClass]="{ 'text-text-tertiary': !segment.typeId }">
    {{ SEGMENT_TYPE_TO_TRANSLATION.get(segment.typeId || null) || '' | translate }}
  </span>
</ng-template>

<ng-template #filtersTemplate>
  <div class="filters mt-2 ml-2">
    <tui-input
      class="name-filter"
      [ngModel]="name()"
      (ngModelChange)="updateName($event)"
      tuiTextfieldSize="m"
      [tuiTextfieldCleaner]="true"
      [tuiTextfieldLabelOutside]="true"
    >
      {{ 'Name' | translate }}
      <input tuiTextfieldLegacy type="text" />
    </tui-input>
  </div>
</ng-template>

<ng-template #nameTemplate let-segment>
  <span [tuiHint]="segment.name" tuiHintPointer>
    {{ segment.name }}
  </span>
</ng-template>

<ng-template #entityNameTemplate let-segment>
  {{ segment?.query?.text?.query?.$from || 'Unknown' }}
</ng-template>

<ng-template #currentVersionTemplate let-segment>
  {{ segment.currentVersion?.version || 'segmentSection.notRecounted' | translate }}
</ng-template>

<ng-template #lastRecountTimeTemplate let-segment>
  {{ (segment.currentVersion?.timestamp | date: 'dd.MM.yyyy HH:mm') || 'segmentSection.notRecounted' | translate }}
</ng-template>

<ng-template #entriesCountTemplate let-segment>
  {{ segment.currentVersion?.snapshot?.count ?? 'segmentSection.notRecounted' | translate }}
</ng-template>

<ng-template #actionButton let-segment let-hovered="hoveredItem">
  @if (!segment.isExternal) {
    <button
      [tuiDropdown]="actions"
      [tuiDropdownManual]="dropdownOpenOnItem === segment"
      [class.visible]="segment === hovered"
      [class.open]="dropdownOpenOnItem === segment"
      (tuiActiveZoneChange)="activeZoneChange($event, segment)"
      (click)="openDropdownOnSegment($event, segment)"
      class="actions"
      appearance="icon"
      iconStart="@tui.ellipsis-vertical"
      tuiIconButton
      size="s"
    ></button>
    <ng-template #actions>
      <tui-data-list *ngIf="dropdownOpenOnItem as segment" class="t-left">
        <button tuiOption (click)="copySegment(segment)">
          <tui-icon class="tui-space_right-1" icon="@tui.copy"></tui-icon>
          {{ 'segmentSection.action.copy' | translate }}
        </button>
        <button
          tuiOption
          class="flex items-center"
          (click)="deleteSegment(segment)"
        >
          <div class="flex items-center space-x-2">
            <tui-icon icon="@tui.trash"></tui-icon>
            <span>{{ 'segmentSection.action.delete' | translate }}</span>
          </div>
        </button>
      </tui-data-list>
    </ng-template>
  }
</ng-template>

<ng-template #topBarButton>
  <button appearance="primary" tuiButton type="button" size="m" (click)="createNew()">
    {{ 'common.create' | translate }}
  </button>
</ng-template>
