<tui-hosted-dropdown [class.empty]="state === null" tuiDropdownAlign="left">
  <tui-primitive-textfield
    [class.empty]="state === null"
    [value]="stateDescription | translate: stateDescriptionParams"
    [editable]="false"
    [disabled]="disabled"
    [tuiTextfieldIcon]="arrow"
    [tuiDropdown]="calendar"
    tuiDropdownAlign="left"
    tuiDropdownOpen
    [tuiDropdownEnabled]="!disabled"
  >
    {{ definition.label | translate }}
  </tui-primitive-textfield>
</tui-hosted-dropdown>

<ng-template #calendar>
  <div style="padding: var(--tui-padding-m); display: flex; flex-wrap: wrap; gap: 1rem">
    <label tuiLabel>
      <input tuiRadio type="radio" [value]="notFilledInValue" [(ngModel)]="selectedRelativeDate" />
      {{ 'segmentEditor.simpleFilter.controls.birthDateNotFilledIn' | translate }}
    </label>

    <label tuiLabel>
      <input
        tuiRadio
        type="radio"
        [value]="todayRelativeDate"
        [(ngModel)]="selectedRelativeDate"
        (ngModelChange)="onSelect(todayRelativeDate)"
      />
      {{ 'segmentEditor.simpleFilter.controls.birthDateToday' | translate }}
    </label>

    <label tuiLabel>
      <input
        tuiRadio
        type="radio"
        [value]="yesterdayRelativeDate"
        [(ngModel)]="selectedRelativeDate"
        (ngModelChange)="onSelect(yesterdayRelativeDate)"
      />
      {{ 'segmentEditor.simpleFilter.controls.birthDateYesterday' | translate }}
    </label>

    <label tuiLabel>
      <input
        tuiRadio
        type="radio"
        [value]="tomorrowRelativeDate"
        [(ngModel)]="selectedRelativeDate"
        (ngModelChange)="onSelect(tomorrowRelativeDate)"
      />
      {{ 'segmentEditor.simpleFilter.controls.birthDateTomorrow' | translate }}
    </label>

    <label tuiLabel>
      <input
        tuiRadio
        type="radio"
        [value]="pastRelativeDate"
        [(ngModel)]="selectedRelativeDate"
        (ngModelChange)="onSelect(pastRelativeDate)"
      />
      <div style="display: flex; gap: 0.5rem; align-items: center">
        <tui-input-number
          [ngModel]="-pastRelativeDate.days"
          (ngModelChange)="updatePastRelativeDateDays($event)"
          style="width: 50px"
          tuiTextfieldSize="s"
          decimal="never"
          [max]="100"
          [min]="0"
        >
        </tui-input-number>
        {{
          'segmentEditor.simpleFilter.controls.dateDynamicDaysAgo'
            | translate
              : {
                  count: pastRelativeDate.days
                }
        }}
      </div>
    </label>

    <label tuiLabel>
      <input
        tuiRadio
        type="radio"
        [value]="futureRelativeDate"
        [(ngModel)]="selectedRelativeDate"
        (ngModelChange)="onSelect(futureRelativeDate)"
      />
      <div style="display: flex; gap: 0.5rem; align-items: center">
        {{ 'segmentEditor.simpleFilter.controls.birthDateAfter' | translate }}
        <tui-input-number
          [ngModel]="futureRelativeDate.days"
          (ngModelChange)="updateFutureRelativeDateDays($event)"
          style="width: 50px"
          tuiTextfieldSize="s"
          decimal="never"
          [max]="100"
          [min]="0"
        >
        </tui-input-number>
        {{
          'segmentEditor.simpleFilter.controls.birthDateDays'
            | translate
              : {
                  days: futureRelativeDate.days
                }
        }}
      </div>
    </label>
  </div>
</ng-template>
