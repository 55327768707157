@if (!disabled) {
  <tui-multi-select
    [ngModel]="state"
    [disabled]="disabled"
    [valueContent]="'segmentEditor.simpleFilter.controls.lookupAnyOf' | translate: { count: state?.length || 0 }"
    [editable]="false"
    (ngModelChange)="updateState($event)"
    [tuiTextfieldCleaner]="true"
  >
    {{ definition.label | translate }}
    <ng-container *tuiDataList>
      <tui-input
        tuiTextfieldIconLeft="@tui.search"
        class="tui-space_all-2"
        #input
        [tuiTextfieldLabelOutside]="true"
        [(ngModel)]="search"
      >
        {{ 'segmentEditor.simpleFilter.controls.lookupSearchInputLabel' | translate }}
      </tui-input>
      <tui-data-list *tuiLet="getAllItems() | async as items" tuiMultiSelectGroup>
        @for (item of items || []; track item.Id) {
          <button tuiOption [value]="item.Id">
            {{ item.Name | translate }}
          </button>
        }
      </tui-data-list>
    </ng-container>
  </tui-multi-select>
} @else {
  <div tuiDropdownOpen [tuiDropdown]="selectedItemsDropdown" [tuiDropdownEnabled]="!!state?.length">
    <div class="flex flex-col gap-1">
      <span class="text-text-secondary">{{ definition.label | translate }}</span>
      <a tuiLink [ngClass]="{ 'opacity-70': !state?.length }" #tuiDropdownHost>
        {{ 'segmentEditor.simpleFilter.controls.lookupAnyOf' | translate: { count: state?.length || 0 } }}
      </a>
    </div>
  </div>

  <ng-template #selectedItemsDropdown>
    <tui-data-list>
      @for (item of selectedItems(); track item.Id) {
        @if (state?.includes(item.Id)) {
          <a tuiOption [value]="item.Id">
            {{ item.Name }}
          </a>
        }
      }
    </tui-data-list>
  </ng-template>
}
