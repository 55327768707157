import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { LogicalOperator } from '@konnektu/metastore';
import { TranslateModule } from '@ngx-translate/core';
import { TuiButton, TuiError } from '@taiga-ui/core';
import { ConditionControlComponent } from '../condition-control/condition-control.component';
import { ConditionDropdownComponent } from '../condition-dropdown/condition-dropdown.component';
import { ConditionBlockDef, ConditionDef } from '../models';
import {
  SimpleFilterCondition,
  SimpleFilterGroup
} from '../simple-filter.component';
import { SimpleFilterTemplateStore } from '../template-store';

@Component({
  selector: 'knk-simple-filter-condition-group',
  templateUrl: 'condition-group.component.html',
  styleUrls: ['condition-group.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    TuiButton,
    TuiError,
    ConditionControlComponent,
    ConditionDropdownComponent
  ]
})
export class ConditionGroupComponent {
  private readonly templateStore = inject(SimpleFilterTemplateStore);

  @Input() group: SimpleFilterGroup | null = null;

  @Input() conditionBlocks: ConditionBlockDef[] = [];

  @Output() groupChange = new EventEmitter<SimpleFilterGroup>();

  @Output() remove = new EventEmitter<void>();

  @Input() disabled = false;

  LogicalOperator = LogicalOperator;

  get error() {
    const error = 'segmentEditor.simpleFilter.groupConditionCountError';
    if (!this.group) {
      return error;
    } else {
      return this.group.items.length < 2 ? error : null;
    }
  }

  trackByIndex = (index: number) => index;

  getConditionDef(id: string) {
    return this.templateStore.conditionDefinitions.find((d) => d.id === id);
  }

  isCondition(
    state: SimpleFilterCondition | SimpleFilterGroup
  ): state is SimpleFilterCondition {
    return 'definitionId' in state;
  }

  isGroup(
    state: SimpleFilterCondition | SimpleFilterGroup
  ): state is SimpleFilterGroup {
    return 'items' in state;
  }

  toggleOperator() {
    if (this.group) {
      const newOperator =
        this.group.operator === LogicalOperator.and
          ? LogicalOperator.or
          : LogicalOperator.and;
      this.groupChange.emit({
        ...this.group,
        operator: newOperator
      });
    }
  }

  updateGroupItem(
    index: number,
    newItem: SimpleFilterCondition | SimpleFilterGroup
  ) {
    if (this.group) {
      const newItems = [...this.group.items];
      newItems.splice(index, 1, newItem);
      this.groupChange.emit({
        operator: this.group.operator,
        items: newItems
      });
    }
  }

  removeGroupItem(index: number) {
    if (this.group) {
      const newItems = [...this.group.items];
      newItems.splice(index, 1);
      this.groupChange.emit({
        operator: this.group.operator,
        items: newItems
      });
    }
  }

  removeSelf() {
    this.remove.emit();
  }

  addItemsToGroup(items: ConditionDef[], groupWith?: LogicalOperator) {
    if (this.group) {
      if (groupWith) {
        const newItems = [
          ...this.group.items,
          {
            operator: groupWith,
            items: items.map((def) => ({
              definitionId: def.id,
              expression: null,
              isValid: () => true,
              state: null
            }))
          }
        ];
        this.groupChange.emit({
          operator: this.group.operator,
          items: newItems
        });
      } else {
        const newItems = [
          ...this.group.items,
          ...items.map((def) => ({
            definitionId: def.id,
            expression: null,
            isValid: () => true,
            state: null
          }))
        ];
        this.groupChange.emit({
          operator: this.group.operator,
          items: newItems
        });
      }
    }
  }
}
