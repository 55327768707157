import { CommonModule } from '@angular/common';
import { NgModule, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRouteSnapshot, RouterModule } from '@angular/router';
import { RestrictAccessDirective } from '@konnektu/acs-helpers';
import {
  ButtonModule,
  DialogModule,
  DropdownModule,
  RadioGroupModule,
  TextInputModule
} from '@konnektu/components';
import {
  IconComponent,
  iconCustomerCard,
  iconDownload,
  iconExpandLess,
  iconExpandMore,
  provideIcons
} from '@konnektu/icons';
import { JobsModule } from '@konnektu/jobs';
import {
  MetastoreComponentsModule,
  MetastoreModule
} from '@konnektu/metastore';
import { SectionV2Module } from '@konnektu/section';
import { SegmentResponseDto, SegmentsService } from '@konnektu/segments-data';
import { WidgetModule } from '@konnektu/widgets';
import { TranslateModule } from '@ngx-translate/core';
import {
  TuiButton,
  TuiDataList,
  TuiDropdown,
  TuiNotification
} from '@taiga-ui/core';
import { TuiBadge } from '@taiga-ui/kit';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { DEFAULT_TEMPLATES } from './constants';
import { SegmentCopyRawDialogComponent } from './pages/segment-card/dialog/copy-raw/copy-raw-dialog.component';
import { SegmentCopyDialogComponent } from './pages/segment-card/dialog/copy-segment/segment-copy-dialog.component';
import { SegmentEditRawDialogComponent } from './pages/segment-card/dialog/edit-raw/edit-raw-dialog.component';
import { EditScheduleDialogComponent } from './pages/segment-card/dialog/edit-schedule/edit-schedule.dialog';
import { SegmentEditDialogComponent } from './pages/segment-card/dialog/edit/edit-dialog.component';
import { SegmentCardComponent } from './pages/segment-card/segment.component';
import { SegmentInfoWidgetComponent } from './pages/segment-card/widgets/info/info.widget';
import { SegmentVersionsWidgetComponent } from './pages/segment-card/widgets/versions/versions.widget';
import { SimpleFilterTemplateStore } from './pages/segment-editor/simple-filter/template-store';
import { SegmentVersionsSectionComponent } from './pages/segment-versions-section/segment-versions-section.component';
import { SegmentsSectionComponent } from './pages/segments-section/segments-section.component';

@NgModule({
  declarations: [
    SegmentCardComponent,
    SegmentEditDialogComponent,
    SegmentEditRawDialogComponent,
    EditScheduleDialogComponent,
    SegmentInfoWidgetComponent,
    SegmentVersionsWidgetComponent,
    SegmentVersionsSectionComponent,
    SegmentCopyDialogComponent,
    SegmentCopyRawDialogComponent
  ],
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule,
    ButtonModule,
    SectionV2Module,
    DialogModule,
    MonacoEditorModule,
    JobsModule,
    MetastoreComponentsModule,
    MetastoreModule,
    RadioGroupModule,
    RestrictAccessDirective,
    WidgetModule,
    ReactiveFormsModule,
    TextInputModule,
    IconComponent,
    MatTooltipModule,
    TuiButton,
    TuiNotification,
    ...TuiDropdown,
    ...TuiDataList,
    TuiBadge,
    TranslateModule,
    RouterModule.forChild([
      {
        path: '',
        data: {
          breadcrumb: 'menu.entry.segments.title'
        },
        children: [
          {
            path: '',
            component: SegmentsSectionComponent,
            data: { breadcrumb: null }
          },
          {
            path: ':segmentId',
            resolve: {
              segment: (route: ActivatedRouteSnapshot) => {
                const segmentService = inject(SegmentsService);
                const id = parseInt(
                  route.paramMap.get('segmentId') as string,
                  10
                );
                return segmentService.byId(id);
              }
            },
            data: {
              breadcrumb: (data: { segment: SegmentResponseDto }) =>
                data.segment.name
            },
            children: [
              {
                path: '',
                data: { breadcrumb: null },
                component: SegmentCardComponent
              },
              {
                path: 'versions',
                data: { breadcrumb: 'segmentsVersion' },
                component: SegmentVersionsSectionComponent
              }
            ]
          }
        ]
      }
    ])
  ],
  providers: [
    provideIcons([
      iconExpandLess,
      iconExpandMore,
      iconCustomerCard,
      iconDownload
    ])
  ]
})
export class SegmentsModule {
  constructor(private readonly templateStore: SimpleFilterTemplateStore) {
    if (!this.templateStore.templates.length) {
      this.templateStore.templates = DEFAULT_TEMPLATES;
    }
  }
}
