import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  MetastoreOperator,
  and,
  equal,
  isNotNull,
  isNull,
  notEqual,
  or
} from '@konnektu/metastore';
import { TranslateModule } from '@ngx-translate/core';
import {
  TuiDataList,
  TuiDropdown,
  TuiError,
  TuiIcon,
  TuiLink
} from '@taiga-ui/core';
import { TuiInputInline } from '@taiga-ui/kit';
import {
  TuiInputModule,
  TuiSelectModule,
  TuiTextfieldControllerModule
} from '@taiga-ui/legacy';
import { StringConditionDef } from '../models';
import { SimpleFilterControl } from './simple-filter-control';

@Component({
  selector: 'knk-simple-filter-string-control',
  template: `
    <tui-select
      [valueContent]="currentOperatorLabel || '' | translate"
      [ngModel]="state?.operator || null"
      [disabled]="disabled"
      (ngModelChange)="updateOperator($event)"
      [tuiTextfieldCleaner]="true"
    >
      {{ definition.label | translate }}
      <tui-data-list *tuiDataList>
        <button *ngFor="let item of operators" tuiOption [value]="item.value">
          {{ item.label | translate }}
        </button>
      </tui-data-list>
    </tui-select>

    <tui-input
      [ngModel]="state?.value"
      [disabled]="disabled"
      *ngIf="showInput"
      (ngModelChange)="updateInputValue($event)"
      [tuiTextfieldLabelOutside]="true"
    >
    </tui-input>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  styles: [
    `
      :host {
        display: flex;
      }

      tui-select {
        width: 300px;
      }

      tui-input {
        width: 200px;
        margin-left: var(--space-m);
      }
    `
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    TuiDropdown,
    TuiDataList,
    TuiIcon,
    TuiLink,
    TuiSelectModule,
    TuiError,
    TuiInputModule,
    TuiInputInline,
    TuiTextfieldControllerModule
  ]
})
export class StringControlComponent
  extends SimpleFilterControl<StringConditionDef>
  implements OnInit
{
  readonly operators = [
    {
      value: MetastoreOperator.eq,
      label: 'segmentEditor.simpleFilter.controls.equalOperator'
    },
    {
      value: MetastoreOperator.ne,
      label: 'segmentEditor.simpleFilter.controls.notEqualOperator'
    },
    {
      value: MetastoreOperator.isNot,
      label: 'segmentEditor.simpleFilter.controls.filledOperator'
    },
    {
      value: MetastoreOperator.is,
      label: 'segmentEditor.simpleFilter.controls.notFilledOperator'
    },
    {
      value: MetastoreOperator.icContains,
      label: 'segmentEditor.simpleFilter.controls.stringContainsOperator'
    },
    {
      value: MetastoreOperator.icNotContains,
      label: 'segmentEditor.simpleFilter.controls.stringNotContainsOperator'
    },
    {
      value: MetastoreOperator.startsWith,
      label: 'segmentEditor.simpleFilter.controls.stringStartsWithOperator'
    },
    {
      value: MetastoreOperator.notStartsWith,
      label: 'segmentEditor.simpleFilter.controls.stringNotStartsWithOperator'
    }
  ];

  get showInput() {
    return this.state
      ? !this.isOperatorWithoutValue(this.state.operator)
      : false;
  }

  get currentOperatorLabel() {
    if (this.state?.operator) {
      return (
        this.operators.find((o) => o.value === this.state?.operator)?.label ||
        undefined
      );
    }
    return undefined;
  }

  ngOnInit(): void {
    this.updateStateAndExpression(this.state || null);
  }

  parseState(state: { value: string; operator: MetastoreOperator }) {
    return state;
  }

  isOperatorWithoutValue(operator: MetastoreOperator) {
    return (
      !operator ||
      [MetastoreOperator.is, MetastoreOperator.isNot].includes(operator)
    );
  }

  updateOperator(operator: MetastoreOperator | null) {
    if (operator === null) {
      this.updateStateAndExpression(null);
    } else {
      this.updateStateAndExpression({
        value: this.state?.value || '',
        operator
      });
    }
  }

  updateInputValue(value: string) {
    this.updateStateAndExpression({
      value,
      operator: this.state?.operator || MetastoreOperator.eq
    });
  }

  createExpression(
    column: string,
    state: { value: string; operator: MetastoreOperator } | null
  ) {
    if (!state) {
      return {};
    }
    if (this.isOperatorWithoutValue(state.operator)) {
      if (state.operator === MetastoreOperator.is) {
        return or(isNull(column), equal(column, ''));
      }
      if (state.operator === MetastoreOperator.isNot) {
        return and(isNotNull(column), notEqual(column, ''));
      }
      return {
        [column]: { [state.operator]: null }
      };
    } else {
      return {
        [column]: { [state.operator]: state.value }
      };
    }
  }
}
