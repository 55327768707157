<div class="knk-segment-info" *ngIf="entity$ | async as entity">
  <div class="knk-segment-info__content">
    <h2>{{ entity.name }}</h2>

    <div class="additional-text">
      <span>{{ entity.typeId ? SegmentTypeIds[entity.typeId] : ('segmentType.noType' | translate) }}</span>
      @if (entity.isExternal) {
        <tui-badge appearance="info">{{ 'segmentCard.externalSegmentBadge' | translate }}</tui-badge>
      }
      @if (entity.isTemporary) {
        <tui-badge appearance="info">{{ 'segmentCard.temporarySegmentBadge' | translate }}</tui-badge>
      }
    </div>

    <h4 class="additional-text">
      {{ entity.timestamp | date: 'dd.MM.yyyy HH:mm' }}
    </h4>

    <h4 class="additional-text" *ngIf="indexedColumns$ | async as cols">
      {{ 'Indexed columns' | translate }}: {{ cols }}
    </h4>
  </div>
</div>
