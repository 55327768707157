import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MetastoreOperator } from '@konnektu/metastore';
import { TranslateModule } from '@ngx-translate/core';
import { TuiLabel } from '@taiga-ui/core';
import { TuiCheckbox } from '@taiga-ui/kit';
import { CheckboxConditionDef } from '../models';
import { SimpleFilterControl } from './simple-filter-control';

@Component({
  selector: 'knk-simple-filter-checkbox-control',
  template: `
    <tui-checkbox-labeled
      [ngModel]="state"
      [disabled]="disabled"
      (ngModelChange)="updateStateAndExpression($event)"
      size="l"
    >
      {{ definition.label | translate }}
    </tui-checkbox-labeled>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, TranslateModule, TuiLabel, TuiCheckbox]
})
export class CheckboxControlComponent
  extends SimpleFilterControl<CheckboxConditionDef>
  implements OnInit
{
  @Input() override definition!: CheckboxConditionDef;

  @Output() override stateChange = new EventEmitter<boolean>();

  @Output() override expressionChange = new EventEmitter<object>();

  ngOnInit() {
    this.updateStateAndExpression(this.state ?? false);
  }

  parseState(state: boolean): boolean {
    return state;
  }

  override updateStateAndExpression(state: boolean): void {
    super.updateStateAndExpression(state);
  }

  createExpression(column: string, state: boolean) {
    return {
      [column]: { [MetastoreOperator.eq]: state }
    };
  }
}
