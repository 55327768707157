import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DialogModule } from '@konnektu/components';
import { MarketingCampaignListItemDto } from '@konnektu/marketing-campaigns/data';
import {
  DefaultDialogComponent,
  injectTuiDialogContext
} from '@konnektu/taiga';
import { INSTANCE_CODE } from '@konnektu/tokens';
import { TranslateModule } from '@ngx-translate/core';
import { TuiIcon } from '@taiga-ui/core';

@Component({
  selector: 'knk-segment-blocking-dialog',
  templateUrl: './segment-blocking-dialog.component.html',
  styleUrls: ['./segment-blocking-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    DialogModule,
    TuiIcon,
    DefaultDialogComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SegmentBlockingDialogComponent {
  protected readonly tenantCode = inject(INSTANCE_CODE);

  protected readonly context = injectTuiDialogContext<
    void,
    { campaigns: MarketingCampaignListItemDto[] }
  >();

  done() {
    this.context.completeWith();
  }
}
