<div style="width: 500px">
  @if (mode === 'calendar' || mode === 'both') {
    <div style="display: flex">
      <tui-calendar
        [maxViewedMonth]="lastMonth.append({ month: -1 })"
        [month]="firstMonth"
        [showAdjacent]="false"
        [value]="stateAsTuiDates"
        [(hoveredItem)]="hoveredItem"
        (dayClick)="onCalendarDayClick($event)"
        (monthChange)="onMonthChangeFirst($event)"
      ></tui-calendar>
      <tui-calendar
        [minViewedMonth]="firstMonth.append({ month: 1 })"
        [month]="lastMonth"
        [showAdjacent]="false"
        [value]="stateAsTuiDates"
        [(hoveredItem)]="hoveredItem"
        (dayClick)="onCalendarDayClick($event)"
        (monthChange)="onMonthChangeLast($event)"
      ></tui-calendar>
    </div>
  }
  @if (mode === 'dynamic' || mode === 'both') {
    <div style="padding: var(--tui-padding-m); display: flex; flex-wrap: wrap; gap: 1rem">
      @if (enableNotFilledInOption()) {
        <label tuiBlock="m">
          <input
            tuiRadio
            type="radio"
            [(ngModel)]="selectedDynamicDate"
            (ngModelChange)="setDateNotFilledIn()"
            [value]="dateNotFilledIn"
          />
          {{ 'segmentEditor.simpleFilter.controls.dateDynamicNotFilledIn' | translate }}
        </label>
      }

      <label tuiBlock="m">
        <input
          tuiRadio
          type="radio"
          [(ngModel)]="selectedDynamicDate"
          (ngModelChange)="onDynamicDateSelect(todayDynamicDate)"
          [value]="todayDynamicDate"
        />
        {{ 'segmentEditor.simpleFilter.controls.dateDynamicToday' | translate: { count: todayDynamicDate.daysAgo } }}
      </label>

      <label tuiBlock="m">
        <input
          tuiRadio
          type="radio"
          [(ngModel)]="selectedDynamicDate"
          (ngModelChange)="onDynamicDateSelect(yesterdayDynamicDay)"
          [value]="yesterdayDynamicDay"
        />
        {{
          'segmentEditor.simpleFilter.controls.dateDynamicYesterday' | translate: { count: yesterdayDynamicDay.daysAgo }
        }}
      </label>

      <label tuiBlock="m">
        <input
          tuiRadio
          type="radio"
          [(ngModel)]="selectedDynamicDate"
          (ngModelChange)="onDynamicDateSelect(dynamicDate)"
          [value]="dynamicDate"
        />
        <div style="display: flex; gap: 0.5rem; align-items: center">
          <tui-input-number
            [tuiNumberFormat]="{ precision: 0 }"
            [ngModel]="dynamicDate.daysAgo"
            (ngModelChange)="updateDaysAgo($event)"
            style="width: 50px"
            tuiTextfieldSize="s"
            [min]="0"
            [max]="100"
          >
          </tui-input-number>
          {{
            'segmentEditor.simpleFilter.controls.dateDynamicDaysAgo'
              | translate
                : {
                    count: dynamicDate.daysAgo
                  }
          }}
        </div>
      </label>
    </div>
  }
</div>
