<tui-primitive-textfield
  [value]="stateDescription | translate: stateDescriptionParams"
  (valueChange)="changeValue($event)"
  [editable]="false"
  [disabled]="disabled"
  [tuiTextfieldIcon]="arrow"
  [tuiTextfieldCleaner]="true"
  [tuiDropdown]="calendar"
  tuiDropdownAlign="left"
  [(tuiDropdownOpen)]="open"
  [tuiDropdownEnabled]="!disabled"
>
  {{ definition.label | translate }}
</tui-primitive-textfield>

<ng-template #calendar>
  <knk-date-selector [date]="state" (dateChange)="onDateUpdate($event)"></knk-date-selector>
</ng-template>
