import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LogicalOperator, MetastoreOperator } from '@konnektu/metastore';
import { TranslateModule } from '@ngx-translate/core';
import { TuiDropdown } from '@taiga-ui/core';
import {
  TUI_ARROW,
  TuiInputRangeModule,
  TuiPrimitiveTextfieldModule,
  TuiTextfieldControllerModule
} from '@taiga-ui/legacy';
import { NumberRangeConditionDef } from '../../models';
import { SimpleFilterControl } from '../simple-filter-control';

@Component({
  selector: 'knk-simple-filter-number-range-control',
  templateUrl: 'number-range-control.component.html',
  styleUrl: 'number-range-control.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    TranslateModule,
    TuiInputRangeModule,
    TuiDropdown,
    TuiTextfieldControllerModule,
    TuiPrimitiveTextfieldModule
  ]
})
export class NumberRangeControlComponent
  extends SimpleFilterControl<NumberRangeConditionDef>
  implements OnInit
{
  open = false;

  arrow = TUI_ARROW;

  get stateDescription() {
    return 'segmentEditor.simpleFilter.controls.numberRange';
  }

  ngOnInit(): void {
    this.updateStateAndExpression(this.state || null);
  }

  parseState(state: object) {
    if (
      'from' in state &&
      typeof state.from === 'number' &&
      'to' in state &&
      typeof state.to === 'number'
    ) {
      return { from: state.from, to: state.to };
    }
    return null;
  }

  updateRange([from, to]: [number, number]) {
    this.updateStateAndExpression({ ...this._state, from, to });
  }

  changeValue(event: string) {
    if (event === '') {
      this.updateStateAndExpression(null);
    }
  }

  createExpression(column: string, state: typeof this.state): object {
    if (!state) {
      return {};
    }
    return {
      [LogicalOperator.and]: [
        {
          [column]: {
            [MetastoreOperator.gte]: state.from
          }
        },
        {
          [column]: {
            [MetastoreOperator.lte]: state.to
          }
        }
      ]
    };
  }
}
