import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
  inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { SegmentResponseDto, SegmentsService } from '@konnektu/segments-data';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TuiValidationError } from '@taiga-ui/cdk';
import { TuiButton, TuiDialogContext, TuiError } from '@taiga-ui/core';
import { TuiButtonLoading, TuiFieldErrorPipe } from '@taiga-ui/kit';
import { TuiInputModule } from '@taiga-ui/legacy';
import { POLYMORPHEUS_CONTEXT } from '@taiga-ui/polymorpheus';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'knk-copy-segment-dialog',
  template: `
    <span class="tui-text_h5">
      {{ 'segmentSection.copyDialog.header' | translate }}
    </span>

    <tui-input class="tui-space_top-2" [formControl]="nameControl">
      {{ 'segmentSection.copyDialog.nameControlLabel' | translate }}
    </tui-input>

    <tui-error
      [formControl]="nameControl"
      [error]="[] | tuiFieldError | async"
    ></tui-error>

    <div class="tui-space_top-2" style="display: flex">
      <button
        style="margin-left: auto"
        appearance="secondary"
        tuiButton
        [disabled]="!!(loading$ | async)"
        (click)="onCancel()"
      >
        {{ 'segmentSection.copyDialog.cancelButtonLabel' | translate }}
      </button>
      <button
        class="tui-space_left-2"
        [disabled]="nameControl.invalid"
        tuiButton
        [loading]="!!(loading$ | async)"
        (click)="onDone()"
      >
        {{ 'segmentSection.copyDialog.doneButtonLabel' | translate }}
      </button>
    </div>
  `,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TuiInputModule,
    TuiButton,
    TranslateModule,
    TuiError,
    TuiFieldErrorPipe,
    TuiButtonLoading
  ],
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CopySegmentDialogComponent implements OnInit {
  private readonly context =
    inject<
      TuiDialogContext<
        SegmentResponseDto | Error | null,
        { segment: SegmentResponseDto }
      >
    >(POLYMORPHEUS_CONTEXT);

  private readonly segmentService = inject(SegmentsService);
  protected readonly loading$ = new BehaviorSubject(false);

  private readonly translate = inject(TranslateService);

  readonly nameControl = new FormControl<string>('', [
    (control) =>
      Validators.required(control)
        ? {
            required: new TuiValidationError(
              this.translate.instant('segmentSection.copyDialog.requiredError')
            )
          }
        : null,
    (control) =>
      Validators.pattern(/[\S]/)(control)
        ? {
            required: new TuiValidationError(
              this.translate.instant('segmentSection.copyDialog.requiredError')
            )
          }
        : null,
    (control) =>
      control.value !== this.context.data.segment.name
        ? null
        : {
            sameAsPrevious: new TuiValidationError(
              this.translate.instant(
                'segmentSection.copyDialog.sameAsBeforeError'
              )
            )
          }
  ]);

  ngOnInit() {
    this.nameControl.setValue(this.context.data.segment.name);
  }

  onCancel() {
    this.context.completeWith(null);
  }

  onDone() {
    this.loading$.next(true);
    this.segmentService
      .createSegment({
        ...this.context.data.segment,
        name: this.nameControl.value as string
      })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (resp) => this.context.completeWith(resp),
        error: () => this.context.completeWith(new Error())
      });
  }

  readonly destroyRef = inject(DestroyRef);
}
