import { NgForOf, NgIf } from '@angular/common';
import * as i0 from '@angular/core';
import { inject, EventEmitter, Component, ChangeDetectionStrategy, Input, Output } from '@angular/core';
import * as i1 from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { TuiControl } from '@taiga-ui/cdk/classes';
import { TUI_DEFAULT_IDENTITY_MATCHER, TUI_FALSE_HANDLER } from '@taiga-ui/cdk/constants';
import { tuiFallbackValueProvider } from '@taiga-ui/cdk/tokens';
import { TuiHintOverflow } from '@taiga-ui/core/directives/hint';
import { TuiBadge } from '@taiga-ui/kit/components/badge';
import { TUI_BLOCK_OPTIONS, TuiBlock } from '@taiga-ui/kit/components/block';
import { PolymorpheusOutlet, PolymorpheusTemplate } from '@taiga-ui/polymorpheus';
const _c0 = a0 => ({
  $implicit: a0
});
const _c1 = () => ({
  standalone: true
});
function TuiFilter_label_0_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 5);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const text_r2 = ctx.polymorpheusOutlet;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", text_r2, " ");
  }
}
function TuiFilter_label_0_tui_badge_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "tui-badge", 6);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const badgeValue_r3 = ctx.ngIf;
    const ctx_r3 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("size", ctx_r3.size);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", badgeValue_r3, " ");
  }
}
function TuiFilter_label_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "label", 1);
    i0.ɵɵtemplate(1, TuiFilter_label_0_span_1_Template, 2, 1, "span", 2)(2, TuiFilter_label_0_tui_badge_2_Template, 2, 2, "tui-badge", 3);
    i0.ɵɵelementStart(3, "input", 4);
    i0.ɵɵlistener("ngModelChange", function TuiFilter_label_0_Template_input_ngModelChange_3_listener($event) {
      const item_r5 = i0.ɵɵrestoreView(_r1).$implicit;
      const ctx_r3 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r3.onCheckbox($event, item_r5));
    });
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const item_r5 = ctx.$implicit;
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵproperty("tuiBlock", ctx_r3.size);
    i0.ɵɵadvance();
    i0.ɵɵproperty("polymorpheusOutlet", ctx_r3.content)("polymorpheusOutletContext", i0.ɵɵpureFunction1(7, _c0, item_r5));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r3.badgeHandler(item_r5));
    i0.ɵɵadvance();
    i0.ɵɵproperty("disabled", ctx_r3.disabledItemHandler(item_r5))("ngModel", ctx_r3.isCheckboxEnabled(item_r5))("ngModelOptions", i0.ɵɵpureFunction0(9, _c1));
  }
}
class TuiFilter extends TuiControl {
  constructor() {
    super(...arguments);
    this.identityMatcher = TUI_DEFAULT_IDENTITY_MATCHER;
    this.items = [];
    this.size = inject(TUI_BLOCK_OPTIONS).size;
    this.disabledItemHandler = TUI_FALSE_HANDLER;
    this.toggledItem = new EventEmitter();
    this.content = ({
      $implicit
    }) => String($implicit);
    this.badgeHandler = item => Number(item);
  }
  onCheckbox(value, item) {
    this.toggledItem.emit(item);
    this.onChange(value ? [...this.value(), item] : this.value().filter(arrItem => !this.identityMatcher(arrItem, item)));
  }
  isCheckboxEnabled(item) {
    return this.value().some(arrItem => this.identityMatcher(arrItem, item));
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTuiFilter_BaseFactory;
      return function TuiFilter_Factory(__ngFactoryType__) {
        return (ɵTuiFilter_BaseFactory || (ɵTuiFilter_BaseFactory = i0.ɵɵgetInheritedFactory(TuiFilter)))(__ngFactoryType__ || TuiFilter);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TuiFilter,
      selectors: [["tui-filter"]],
      hostVars: 1,
      hostBindings: function TuiFilter_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("data-size", ctx.size);
        }
      },
      inputs: {
        identityMatcher: "identityMatcher",
        items: "items",
        size: "size",
        disabledItemHandler: "disabledItemHandler",
        content: "content",
        badgeHandler: "badgeHandler"
      },
      outputs: {
        toggledItem: "toggledItem"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([tuiFallbackValueProvider([])]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 1,
      vars: 1,
      consts: [["appearance", "", "class", "t-item", 3, "tuiBlock", 4, "ngFor", "ngForOf"], ["appearance", "", 1, "t-item", 3, "tuiBlock"], ["tuiHintOverflow", "", "class", "t-text", 4, "polymorpheusOutlet", "polymorpheusOutletContext"], ["appearance", "neutral", 3, "size", 4, "ngIf"], ["tuiBlock", "", "type", "checkbox", 3, "ngModelChange", "disabled", "ngModel", "ngModelOptions"], ["tuiHintOverflow", "", 1, "t-text"], ["appearance", "neutral", 3, "size"]],
      template: function TuiFilter_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, TuiFilter_label_0_Template, 4, 10, "label", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngForOf", ctx.items);
        }
      },
      dependencies: [FormsModule, i1.CheckboxControlValueAccessor, i1.NgControlStatus, i1.NgModel, NgForOf, NgIf, PolymorpheusOutlet, TuiBadge, TuiBlock, TuiHintOverflow],
      styles: ["[_nghost-%COMP%]{display:block;margin-bottom:calc(-1 * var(--t-gap));--t-gap: .25rem}[data-size=l][_nghost-%COMP%]{--t-gap: .5rem}.t-item[_ngcontent-%COMP%]{max-inline-size:11rem;margin:0 var(--t-gap) var(--t-gap) 0}.t-item[_ngcontent-%COMP%]:last-child{margin-right:0}.t-text[_ngcontent-%COMP%]{white-space:nowrap;overflow:hidden;text-overflow:ellipsis}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiFilter, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'tui-filter',
      imports: [FormsModule, NgForOf, NgIf, PolymorpheusOutlet, PolymorpheusTemplate, TuiBadge, TuiBlock, TuiHintOverflow],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [tuiFallbackValueProvider([])],
      host: {
        '[attr.data-size]': 'size'
      },
      template: "<label\n    *ngFor=\"let item of items\"\n    appearance=\"\"\n    class=\"t-item\"\n    [tuiBlock]=\"size\"\n>\n    <span\n        *polymorpheusOutlet=\"content as text; context: {$implicit: item}\"\n        tuiHintOverflow\n        class=\"t-text\"\n    >\n        {{ text }}\n    </span>\n    <tui-badge\n        *ngIf=\"badgeHandler(item) as badgeValue\"\n        appearance=\"neutral\"\n        [size]=\"size\"\n    >\n        {{ badgeValue }}\n    </tui-badge>\n    <input\n        tuiBlock\n        type=\"checkbox\"\n        [disabled]=\"disabledItemHandler(item)\"\n        [ngModel]=\"isCheckboxEnabled(item)\"\n        [ngModelOptions]=\"{standalone: true}\"\n        (ngModelChange)=\"onCheckbox($event, item)\"\n    />\n</label>\n",
      styles: [":host{display:block;margin-bottom:calc(-1 * var(--t-gap));--t-gap: .25rem}:host[data-size=l]{--t-gap: .5rem}.t-item{max-inline-size:11rem;margin:0 var(--t-gap) var(--t-gap) 0}.t-item:last-child{margin-right:0}.t-text{white-space:nowrap;overflow:hidden;text-overflow:ellipsis}\n"]
    }]
  }], null, {
    identityMatcher: [{
      type: Input
    }],
    items: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    disabledItemHandler: [{
      type: Input
    }],
    toggledItem: [{
      type: Output
    }],
    content: [{
      type: Input
    }],
    badgeHandler: [{
      type: Input
    }]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiFilter };
